import {connect} from "react-redux";
import React, {Component} from "react";
import {formatQuery} from "../controllers/utilities/string-utils";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import cookie from "react-cookies";
import Lambda from "../controllers/utilities/aws-lambda";
import KaisakuApi, {getFullPath} from "../controllers/kaisaku-api";
import {navigate} from "gatsby";
import {Button} from "semantic-ui-react";
import View from "../components/forms/teaching-detail/teaching-detail-view4";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    onContinue() {
        let { pendingTransactionId, newAccessToken, newGameId, newAttributionId, back } = formatQuery(this.props.location.search);
        if (back === undefined) {
            if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))) {
                newAccessToken = cookie.load("_accessToken");
                newGameId = cookie.load("_gameId");
                newAttributionId = cookie.load("_attributionId");
                pendingTransactionId = cookie.load("_transactionId");
            }
            if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)) {
                let platform = cookie.load("_platform");
                const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                // console.log(url);
                window.location.href = url;
            } else {
                navigate("/confirm-purchase");
            }
        } else {
            navigate(`/sdk-teachingsdkcallback`);
        }
    }

    async componentDidMount() {        
    }

    render() {
        return(
            <View
            onContinue={this.onContinue.bind(this)}
            />
        );
    }


});